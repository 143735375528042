html,
body,
#root {
  display: block;
  height: 100%;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}